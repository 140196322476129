import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Translate from "../components/translate"
import TranslateHtml from "../components/translateHtml"

const page = ({ data }) => {
  const post = data.page

  return (
    <Layout>
      <div>
        <h1>
          <Translate object={post} prop={'title'} />
        </h1>

        <TranslateHtml object={post} prop={'body'} />
      </div>
    </Layout>
  )
}

export default page

export const query = graphql`
  query($slug: String!) {
    page(slug: {eq: $slug}) {
      id
      slug
      title_fr
      title_nl
      body_fr
      body_nl
    }
  }
`
