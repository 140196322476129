import React from "react"
import useIntl from "../helpers/useIntl"

const Translate = ({object, prop}) => {
  const intl = useIntl()

  return (
    <React.Fragment>
      {intl.translate(object, prop)}
    </React.Fragment>
  )
}

export default Translate
